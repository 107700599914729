<template>
  <v-card-title>
    {{ title }}
    <v-spacer />
    <slot>
      <default-button
        @click="$emit('refresh')"
        icon="fa-sync"
        data-test="refresh-btn"
        >Refresh</default-button
      >
    </slot>
  </v-card-title>
</template>

<script>
import DefaultButton from "@/components/widgets/DefaultButton";

export default {
  components: { DefaultButton },
  props: ["title"],
};
</script>
