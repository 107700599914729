<template>
  <v-row class="ma-4" v-if="account">
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-snackbar v-model="saved" color="success">Changes saved!</v-snackbar>
    <page-header />

    <v-col cols="12">
      <v-card tile>
        <table-header title="Python configuration"><span></span></table-header>

        <v-form v-model="valid">
          <v-alert
            v-if="account && account.product.name === 'Packagr - Basic plan'"
            border="bottom"
            colored-border
            class="ma-4"
            type="warning"
            elevation="2"
          >
            As a basic plan user, you do not have the ability to use Upstream
            mirroring for your Python packages. To enable this feature, please
            <router-link to="/billing">Upgrade your account</router-link>
          </v-alert>
          <v-card-text>
            <v-row>
              <v-col cols="2">
                <div class="mt-3">Upstream mirroring?</div>
              </v-col>
              <v-col cols="10">
                <v-switch
                  class="mt-0"
                  persistent-hint
                  :disabled="account.product.name === 'Packagr - Basic plan'"
                  hint="Upstream mirroring lets you cache external dependencies in Packagr"
                  :label="form.upstreamMirroring ? 'Enabled' : 'Disabled'"
                  v-model="form.upstreamMirroring"
                ></v-switch>
              </v-col>
              <v-col cols="2">
                <div class="mt-3">PYPI mirror URL</div>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  placeholder="Enter a URL"
                  hint="By default, pubic Python packages will be pulled from https://pypi.org/simple. If you'd like to use a different resource, enter it above"
                  solo
                  :disabled="
                    !form.upstreamMirroring ||
                    account.product.name === 'Packagr - Basic plan'
                  "
                  v-model="form.pypiMirrorUrl"
                  :persistent-hint="form.upstreamMirroring"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <default-button
            icon="fa-plus"
            color="success"
            :loading="loading"
            :disabled="
              !valid || account.product.name === 'Packagr - Basic plan'
            "
            @click="saveChanges"
          >
            Save changes
          </default-button>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import PageHeader from "../components/widgets/PageHeader";
import TableHeader from "../components/widgets/TableHeader";
import DefaultButton from "../components/widgets/DefaultButton";
import { mutations } from "../gql";

const { editAccountConfig: mutation } = mutations;

export default {
  components: { DefaultButton, TableHeader, PageHeader },
  props: ["accountConfiguration", "account"],
  data: () => ({
    valid: null,
    form: {
      upstreamMirroring: true,
      pypiMirrorUrl: null,
    },
    loading: false,
    error: false,
    errorMessage: null,
    saved: false,
  }),
  mounted() {
    this.setForm();
  },
  watch: {
    accountConfiguration() {
      this.setForm();
    },
  },
  methods: {
    setForm() {
      if (this.accountConfiguration) {
        const { pypiMirrorUrl, upstreamMirroring } = this.accountConfiguration;
        this.form = { pypiMirrorUrl, upstreamMirroring };
      }
    },
    saveChanges() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: this.form,
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.saved = true;
            this.$emit("save");
          }
        });
    },
  },
};
</script>
