<template>
  <account-configuration
    v-if="accountConfiguration"
    :accountConfiguration="accountConfiguration"
    :account="account"
    @save="$apollo.queries.accountConfiguration.refetch()"
  ></account-configuration>
</template>

<script>
import AccountConfiguration from "../components/AccountConfiguration";
import { queries } from "../gql";

const { accountConfiguration, account } = queries;

export default {
  components: { AccountConfiguration },
  apollo: { accountConfiguration, account },
};
</script>
